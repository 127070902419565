import { useState } from 'react';

import { ArrowRight, ThumbsUp, ThumbsDown } from '@vgno/icons';
import { delay, trackEngagement } from './utils.js';

import './Feedback.css';

const Feedback = ({ summaries, customTrackingData }) => {
    const [feedbackGiven, setFeedbackGiven] = useState(false);
    const [animateShortOut, setAnimateShortOut] = useState(false);
    const [animateLongIn, setAnimateLongIn] = useState(false);

    const switchFromShortToLong = async () => {
        // Animate the short feedback out
        setAnimateShortOut(true);
        await delay(300);
        setAnimateShortOut(false);

        setFeedbackGiven(true);

        // Animate the long feedback in
        setAnimateLongIn(true);
        await delay(300);
        setAnimateLongIn(false);
    };

    const thumbsUp = async () => {
        switchFromShortToLong();

        trackEngagement('Click', {
            contentId: 'feedback-thumbsup',
            id: 'feedback-thumbsup',
            custom: {
                summaries,
                ...customTrackingData,
            },
        });
    };

    const thumbsDown = async () => {
        switchFromShortToLong();

        trackEngagement('Click', {
            contentId: 'feedback-thumbsdown',
            id: 'feedback-thumbsdown',
            custom: {
                summaries,
                ...customTrackingData,
            },
        });
    };

    return (
        <div className="feedback">
            {!feedbackGiven ? (
                <div className={`short ${animateShortOut ? 'fade-up' : ''}`}>
                    <div className="question">Føler du deg oppdatert på nyhetene nå?</div>
                    <div className="buttons">
                        <button className="survey-button">
                            <ThumbsUp className="thumbsup" onClick={thumbsUp} />
                        </button>
                        <button className="survey-button">
                            <ThumbsDown className="thumbsdown" onClick={thumbsDown} />
                        </button>
                    </div>
                </div>
            ) : (
                <a className={`long ${animateLongIn ? 'fade-up' : ''}`} href="https://iter.ly/tvgm9" target="_blank" rel="noreferrer">
                    Takk! Mer du vil si?&nbsp;<span class="underline">Gi tilbakemelding</span> <ArrowRight />
                </a>
            )}
        </div>
    );
};

export default Feedback;
