import { fetchJson } from './fetch-helpers';
import { hasSession, isLoggedIn } from '../auth/schibsted-account';

const PRODUCTS = ['100023', '301784', '301814', 'FEA-VG-PLUSARTICLES'];
const ACCESS_PATH = `https://access.vg.no/v1/access?pids=${PRODUCTS.join(
    ',',
)}&spid_instance=spid.no&source=vg.no`;

let accessStatus = null;

export async function hasAccess() {
    if (accessStatus !== null) {
        return accessStatus;
    }

    if (!(await isLoggedIn())) {
        accessStatus = false;
        return false;
    }

    try {
        await hasSession();

        const products = await fetchJson(ACCESS_PATH, {
            credentials: 'include',
        });

        if (products.entitled) {
            accessStatus = true;
            return true;
        }
    } catch {
        accessStatus = false;
        return false;
    }

    accessStatus = false;

    return false;
}
