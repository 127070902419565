import { Tracker as PulseTracker } from '@spt-tracking/pulse-sdk';
import { hasSession } from '../auth/schibsted-account';
import { hasAccess } from '../utils/access';
import { NewsMediaPulseTracker } from '@schibsted/pulse-news-media';
import {
    removeUtmParams,
    updateHistoryState,
} from '../utils/removeUtmParams.js';
import { capitalize, getUTMParams } from '@vgno/utils';

const getActor = async () => {
    try {
        const user = await hasSession();
        if ('userId' in user) {
            const isPlussUser = await hasAccess();

            return {
                id: user.userId,
                realm: 'spid.no',
                subscriptionName: isPlussUser ? 'VG+' : 'No',
            };
        }
    } catch {
        // continue
    }
    return { id: undefined };
};

const routeToPulseObjectMapping = {
    front: {
        id: 'front',
        contentId: 'front',
        type: 'Frontpage',
        category: 'vgfront',
        custom: {
            frontVersion: 'vgbeta',
            supportsImportmap: !!(
                HTMLScriptElement.supports &&
                HTMLScriptElement.supports('importmap')
            ),
            hasDarkmode: window.matchMedia('(prefers-color-scheme: dark)')
                .matches,
        },
    },
    'front-beta': {
        id: 'front-beta',
        contentId: 'front-beta',
        type: 'Frontpage',
    },
};

const utmTags = getUTMParams(window.location.toString(), true);
const ORIGIN_MAP = {
    vgfront: {
        id: 'sdrn:vg:frontpage',
        type: 'Frontpage',
    },
};

export const newsMediaPulse = NewsMediaPulseTracker.fromPulseTracker(
    PulseTracker,
    {
        deployStage: 'pro',
        providerId: 'vg',
        productName: 'vg',
        sdkConfigInput: {
            autoCreateConsents: true,
            cisEnabled: true,
            nativeJwe: window.hermesJwe,
            requireAdvertisingOptIn: true,
            altEventHandler: window.pulseEventHandler,
            deployStage: 'pro',
        },
        builders: {
            actor: getActor(),
            provider: {
                id: 'sdrn:schibsted:client:vg',
                name: 'vg',
                productTag: 'vg',
            },
            object: routeToPulseObjectMapping[
                document.body.getAttribute('data-route')
            ],
            origin: {
                channel: utmTags.medium,
                campaign: utmTags.campaign,
                content: utmTags.content,
                id: utmTags.source
                    ? (ORIGIN_MAP[utmTags.source]?.id ?? utmTags.source)
                    : 'unknown',
                source: utmTags.source,
                terms: utmTags.terms,
                type: utmTags.source
                    ? (ORIGIN_MAP[utmTags.source]?.type ??
                      capitalize(utmTags.source?.split(':')[2] ?? ''))
                    : 'unknown',
                url: utmTags.url || document.referrer,
            },
        },
    },
);

/** @deprecated use newsMediaPulse instead */
export const trackerInstance = newsMediaPulse.pulseTracker;

if (window.varnishVaryConfig) {
    newsMediaPulse.updateBaseEvent({
        experiments: window.varnishVaryConfig.map((type) => ({
            id: type,
            platform: 'vg',
        })),
    });
}

const externalInstances = {};
const exposePulseAsGlobal = () => {
    // Run window.pulse.q
    const pulseQueue = window.pulse?.q || [];
    window.pulse = (...args) => {
        if (args.length === 0) {
            return;
        }

        if (typeof args[0] === 'function') {
            args[0](trackerInstance);
        }

        // backwards compatibility for the pulse autotracker
        if (typeof args[0] === 'string') {
            if (args[0] === 'init') {
                const [, pulseClientId, sdkConfig, , trackerId] = args;

                externalInstances[trackerId] = new PulseTracker(pulseClientId, {
                    useBeacon: true,
                    useBeaconWhenAvailable: true,
                    ...sdkConfig,
                });
            } else {
                const [trackerId, cb] = args;

                if (externalInstances[trackerId]) {
                    cb(externalInstances[trackerId]);
                }
            }
        }
    };
    pulseQueue.forEach((fn) => {
        window.pulse.apply(null, fn);
        pulseQueue.shift();
    });
    window.pulse.q = pulseQueue;
};

exposePulseAsGlobal();

export async function trackPageView() {
    await trackerInstance.trackPageView();

    try {
        const { object } = trackerInstance.builders;
        localStorage.setItem('previousArticle', null);
        localStorage.setItem(
            'previousPage',
            JSON.stringify({
                id: object ? object.id : null,
                url: window.location.href,
                referrer: window.document.referrer,
            }),
        );
    } catch (err) {
        console.error(err);
    }
    const cleanPath = removeUtmParams(window.location.href);
    if (cleanPath !== window.location.pathname + window.location.search) {
        updateHistoryState(cleanPath);
    }
}

/**
 * @returns {Promise<string>}
 */
export async function getEnvironmentId() {
    return newsMediaPulse.pulseTracker.getEnvironmentId();
}

/**
 * @typedef {{ '@id': string; '@type': string; name?: string; url: string } & Record<string, any>} Target
 * @typedef {{ '@id': string; target?: Target } & Record<string, any>} Element
 */

/**
 * trackEngagement
 *
 * @param {Object} params
 * @param {string} [params.type]
 * @param {string} [params.action]
 * @param {string} [params.elementId]
 * @param {string} [params.elementType]
 * @param {string} [params.elementName]
 * @param {string} [params.elementAction]
 * @param {string} [params.overrideId]
 * @param {Element[]} [params.elements]
 * @param {Target} [params.target]
 * @param {string} [params.intent]
 * @param {number} [params.scrollPosition]
 * @param {number} [params.duration]
 * @param {any} [params.custom]
 * @param {any} [params.objectExtra]
 * @param {MouseEvent} [params.event]
 * @param {string[]} [params.tags]
 * @param {any} [params.partnerstudio]
 * @param {string} [params.productTag]
 * @returns void
 */
export function trackEngagement({
    type = 'Engagement',
    action,
    elementId,
    elementType,
    elementName,
    elementAction,
    elements,
    target,
    intent,
    scrollPosition,
    duration,
    custom = {},
    objectExtra,
    overrideId = null,
    tags,
    partnerstudio,
    productTag,
}) {
    const parentObject = trackerInstance.builders.object || {};
    const parentObjectId = parentObject.id || '';
    const parentObjectType = (parentObject.type || '').toLowerCase();

    // TODO: Remove this when out of beta
    custom.frontTrackingVersion = 2.1;
    custom.frontVersion = 'vgbeta';
    custom.isLegacyTest = !process.isModern;

    const parentId = `sdrn:vg:${parentObjectType}:${parentObjectId}`;
    const id = overrideId ?? `${parentId}:element:${elementId}`;

    const object = {
        '@id': id,
        '@type': 'UIElement',
        contentId: elementId,
        elementType,
        name: elementName,
        action: elementAction,
        page: {
            '@id': parentId,
            '@type': 'Frontpage',
            url: location.origin,
        },
        custom,
        tags,
    };

    if (objectExtra && Object.assign) {
        Object.assign(object, objectExtra);
    }

    const event = {
        type,
        action,
        object,
        origin: {
            '@id': parentId,
            '@type': parentObjectType,
            url: parentObject.url,
        },
        elements,
        target,
        intent,
        scrollPosition,
        duration,
        partnerstudio,
        provider: {
            productTag: productTag,
        },
    };

    trackerInstance.track('trackerEvent', event);
}

export default {
    getEnvironmentId,
    trackPageView,
};
