import React, { useState, useEffect } from 'react';
import {
    trackEngagement,
} from './utils.js';

const HEI_URL = "https://vg.no/hei"
const API_URL = "https://www.vg.no/spesial/drive/1sovSkmkUidChHcm4vdn1xGVdGiA4qvaWPA0Am4_p1Ag.json";

const Preprompts = ({}) => {
    const [preprompts, setPreprompts] = useState([]);

    const fetchData = async (signal) => {
        try {
            const response = await fetch(API_URL);
            const json = await response.json();

            if (json?.data.PROD) {
                const allPreprompts = json?.data?.PROD?.map(preprompt => {
                    const url = new URL(HEI_URL);
                    url.searchParams.append("q", preprompt["Spørsmål"]);
                    url.searchParams.append("utm_source", "vgfront");
                    url.searchParams.append("utm_medium", "skjedd-siden-sist");
                    url.searchParams.append("utm_campaign", "preprompt");

                    return {
                        question: preprompt["Spørsmål"],
                        url: url.toString(),
                    };
                }) || [];

                const preprompts = getRandomItems(allPreprompts, 3);

                setPreprompts(preprompts);

                trackEngagement('View', {
                    id: "preprompts",
                    custom: {
                        preprompts: preprompts.map((preprompt) => preprompt.question)
                    },
                });
            }
        } catch (error) {
            if (!signal.aborted) {
                console.error('Error fetching personalized stories:', error);
            }
        }
    };

    const trackPrepromptClick = (preprompt) => {
        trackEngagement('Click', {
            id: "preprompt",
            custom: {
                preprompt: preprompt.question
            },
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="hei">
            <div className="preprompts">
                {preprompts.map((preprompt, i) => (
                    <a key={i} className="preprompt" href={preprompt.url} onClick={() => trackPrepromptClick(preprompt)}>{preprompt.question}</a>
                ))}
            </div>
        </div>
    );
};

function getRandomItems(arr, count) {
    const result = [];
    const indices = new Set();

    while (indices.size < count) {
        indices.add(Math.floor(Math.random() * arr.length));
    }

    indices.forEach(i => result.push(arr[i]));
    return result;
}

export default Preprompts;
