import { trackEngagement } from './utils.js';
import { trackEngagement as originalTrackEngagement } from '../../tracking/pulse.js';

export const trackLoad = (custom) => {
    trackEngagement('Load', {
        name: 'Snakkis Chatbot',
        contentId: 'chatbot',
        custom: custom,
    });
};

export const trackShowMore = ({ story, custom }) => {
    trackEngagement('Click', {
        storyId: story.uuid,
        contentId: 'show-more',
        id: 'show-more',
        name: story.story_name,
        custom,
    });

    originalTrackEngagement({
        action: 'Click',
        intent: 'Open',
        type: 'Engagement',
        elementId: 'skjedd-siden-sist',
        elementType: 'FrontTracking',
        objectExtra: {
            drFrontRow: 3,
            row: 3,
            position: 1,
            section: 'hovedlopet',
            size: 1,
        },
        target: {
            '@id': 'sdrn:vg:frontpage:front:element:skjedd-siden-sist',
            '@type': 'UIElement',
            name: '',
            url: '',
        },
    });
};

export const trackShowLess = ({ story, custom }) => {
    trackEngagement('Click', {
        storyId: story.uuid,
        contentId: 'show-less',
        id: 'show-less',
        name: story.story_name,
        custom,
    });
};

export const trackImpression = (node, custom) => {
    let hasBeenInView = false;
    let inViewTimeout = null;
    let enterTime = null; // Timestamp when the element enters the viewport

    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                // If >= 50% of the node is in view
                if (
                    entry.isIntersecting &&
                    !hasBeenInView &&
                    entry.intersectionRatio >= 0.5
                ) {
                    // Since we have multiple thresholds, we need to clear the timeout to
                    // prevent multiple impressions, since the observer can trigger multiple times
                    if (inViewTimeout) {
                        clearTimeout(inViewTimeout);
                    }

                    inViewTimeout = setTimeout(() => {
                        hasBeenInView = true;
                        enterTime = performance.now(); // Record the entry timestamp

                        trackEngagement('View', {
                            name: 'Snakkis Chatbot',
                            contentId: 'chatbot',
                            custom: custom,
                        });

                        originalTrackEngagement({
                            action: 'Scroll',
                            intent: 'View',
                            type: 'Engagement',
                            elementId: 'FrontTracking',
                            elementType: 'FrontTracking',
                            elements: [
                                {
                                    '@id': `sdrn:vg:frontpage:front:element:skjedd-siden-sist`,
                                    '@type': 'UIElement',
                                    drFrontRow: 3,
                                    row: 3,
                                    position: 1,
                                    section: 'hovedlopet',
                                    size: 1,
                                    target: {
                                        '@id': 'sdrn:vg:frontpage:front:element:skjedd-siden-sist',
                                        '@type': 'UIElement',
                                        name: '',
                                        url: '',
                                    },
                                },
                            ],
                        });
                    }, 1000);
                }
                // If user scrolls away after having been in view
                else if (!entry.isIntersecting && hasBeenInView) {
                    observer.disconnect();

                    const leaveTime = performance.now(); // Get the timestamp when leaving
                    const duration = leaveTime - enterTime; // Calculate the duration in milliseconds

                    trackEngagement('View', {
                        id: 'leave',
                        name: 'Snakkis Chatbot',
                        contentId: 'chatbot',
                        custom: {
                            ...custom,
                            duration: duration / 1000, // Convert to seconds
                        },
                    });
                }

                // Clear the timer if no longer intersecting
                if (!entry.isIntersecting && inViewTimeout) {
                    clearTimeout(inViewTimeout);
                }
            });
        },
        {
            threshold: [0, 0.5, 1],
        },
    );

    observer.observe(node);
};
