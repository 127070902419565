export const API_URL = 'https://latest-news-chatbot-bff.prod.sk8s.vg.no/';
export const DIRECT_API_URL = 'https://latest-news-chatbot.prod.sk8s.vg.no/';

/**
 * Tracks user engagement events.
 * @param {string} action
 * @param {Object} object
 * @returns {void}
 */
export function trackEngagement(action, object) {
    const extraId = [object.storyId, object.id].filter(Boolean).join(':');
    const custom = object.custom;

    const payload = {
        type: 'Engagement',
        action,
        object: {
            type: 'UIElement',
            id: `snakkis-chatbot${extraId ? `:${extraId}` : ''}`,
            name: object.name,
            contentId: object.contentId,
        },
    };

    if (custom) {
        payload.object['spt:custom'] = custom;
    }

    window.pulse((sdk) => {
        sdk.track('trackerEvent', payload);
    });
}

/**
 * Fetch wrapper with timeout and default headers.
 * @param {string|URL} url
 * @param {RequestInit} options
 * @param {number} timeout
 * @returns {Promise<Response>}
 */
export function fetchWithTimeout(url, options = {}, timeout = 20000) {
    return fetch(url, {
        signal: AbortSignal.timeout(timeout),
        credentials: 'include',
        ...options,
    });
}

/**
 * Fetch wrapper with retries.
 * @param {string|URL} url
 * @param {RequestInit} options
 * @param {number} retries
 * @param {number} timeout
 * @returns {Promise<Response>}
 */
export async function fetchWithRetry(
    url,
    options,
    retries = 3,
    timeout = 30000,
) {
    const { signal } = options;

    for (let attempt = 0; attempt < retries; attempt++) {
        try {
            const response = await fetchWithTimeout(url, options, timeout);
            return response;
        } catch (error) {
            if (signal?.aborted) {
                throw new Error('Request aborted');
            }
            if (attempt === retries - 1) {
                throw error;
            }
        }
    }
}

/**
 * Delays execution by a given number of milliseconds.
 * @param {number} ms
 * @returns {Promise<void>}
 */
export function delay(ms) {
    return new Promise((res) => setTimeout(res, ms));
}

/**
 * Returns an integer denoting the (pretended) number of hours since the user last visited the site.
 * @returns {number} Number of hours since the user last visited the site.
 */
export function getPretendHoursSinceLastSeen() {
    const urlParams = new URLSearchParams(window.location.search);

    // Get the value of either 'debug_days' (in days) or 'skjeddsidensist' (in hours) from the URL
    const debugDays = urlParams.get('debug_days');
    const skjeddsidensist = urlParams.get('skjeddsidensist');

    if (debugDays) {
        return parseInt(debugDays, 10) * 24;
    }

    if (skjeddsidensist) {
        return parseInt(skjeddsidensist, 10);
    }
}

/**
 * Appends `debug_days` parameter to URL if `debug_days` (in days) or 'skjeddsidensist' (in hours) is present in the main URL.
 *
 * TODO: Right now 'skjeddsidensist' is rounded up the nearest day and converted to 'debug_days' (in days), since the API only
 * supports that.
 *
 * @param {URL} url
 * @returns {void}
 */
export function addDebugDays(url) {
    const pretendHoursSinceLastSeen = getPretendHoursSinceLastSeen();

    if (pretendHoursSinceLastSeen) {
        const debugDays = Math.ceil(pretendHoursSinceLastSeen / 24);
        url.searchParams.append('debug_days', debugDays);
    }
}

/**
 * Returns a promise that resolves after the next two animation frames.
 * @returns {Promise<void>}
 */
export function nextFrame() {
    return new Promise((resolve) =>
        requestAnimationFrame(() => requestAnimationFrame(resolve)),
    );
}

/**
 * Returns an integer denoting the user's propensity to use Skjedd siden sist.
 * @returns {Promise<number>}
 */
export const getPropensityToUse = async () => {
    const url = 'https://retargeting.vg.no/signal/smart-skjedd-siden-sist';

    const response = await fetch(url, {
        credentials: 'include',
        //headers: {
        //    'Content-Type': 'application/json',
        //},
    });

    const data = await response.json();

    return data.value;
};

/**
 * Returns the last seen timestamp from local storage.
 * @returns {number|null}
 */
export const getLastSeenFromLocalStorage = () => {
    const lastSeen = localStorage.getItem('snakkis.last_seen');
    return lastSeen ? Math.floor(parseInt(lastSeen, 10) / 1000) : null;
};

export const getLastSeenFromServer = async () => {
    const response = await fetchWithRetry(DIRECT_API_URL + 'last_seen', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    // 404 Not Found means the user has never been seen before. In that case,
    // return null to indicate that the user should be considered new.
    if (response.status === 404) {
        return null;
    }

    const data = await response.json();

    // `last_seen` is a UNIX timestamp in seconds denoting the last time
    // the user was seen.
    return data.last_seen;
};

export const setLastSeenOnServer = async () => {
    await fetchWithRetry(DIRECT_API_URL + 'last_seen', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * Sets the last seen timestamp in local storage.
 * @returns {void}
 */
export function setLastSeenInLocalStorage() {
    localStorage.setItem('snakkis.last_seen', Date.now().toString());
}

/**
 * Returns the last seen timestamp from session storage.
 * @returns {number|null}
 */
export const getLastSeenFromSessionStorage = () => {
    const lastSeen = sessionStorage.getItem('skjedd-siden-sist:last-seen');
    return lastSeen ? Math.floor(parseInt(lastSeen, 10)) : null;
};

/**
 * Sets the last seen timestamp in session storage.
 * @param {number} timestamp
 * @returns {void}
 */
export function setLastSeenInSessionStorage(timestamp) {
    sessionStorage.setItem(
        'skjedd-siden-sist:last-seen',
        JSON.stringify(timestamp),
    );
}

/**
 * Removes last seen from session storage.
 * @returns {void}
 */
export function removeLastSeenFromSessionStorage() {
    sessionStorage.removeItem('skjedd-siden-sist:last-seen');
}

/**
 * Returns stories from session storage.
 * @returns {Object|null}
 */
export function getStoriesFromSessionStorage() {
    const data = sessionStorage.getItem('skjedd-siden-sist:stories');

    return data ? JSON.parse(data) : null;
}

/**
 * Sets stories in session storage.
 * @returns {void}
 */
export function setStoriesInSessionStorage(stories) {
    sessionStorage.setItem(
        'skjedd-siden-sist:stories',
        JSON.stringify(stories),
    );
}

/**
 * Removes stories from session storage.
 * @returns {void}
 */
export function removeStoriesFromSessionStorage() {
    sessionStorage.removeItem('skjedd-siden-sist:stories');
}

/**
 * Determines whether an update should be fetched, based on the last seen timestamp.
 *
 * The update interval is set to 8 hours.
 **
 * @returns {boolean} True if an update should be fetched, otherwise false.
 */
export const shouldGetUpdate = (lastSeen) => {
    // If there's no record of the last seen time, don't trigger an update
    if (!lastSeen) {
        return false;
    }

    const now = Math.floor(Date.now() / 1000); // Current time in seconds
    const elapsedTime = now - lastSeen;

    return elapsedTime >= 3600 * 8;
};

/**
 * Fetches the last seen timestamp.
 *
 * For logged-in users, the timestamp is fetched from the server.
 * For anonymous users, the timestamp is fetched from local storage.
 *
 * @returns {Promise<Object>}
 */
export const getLastSeen = async (user) => {
    // If last seen is set in session storage, it means that the user just logged in
    // for Skjedd siden sist, and this was when they were actually last seen
    // prior to that.
    if (getLastSeenFromSessionStorage()) {
        console.log(
            'Skjedd Siden Sist: Fetched last seen from session storage',
        );
        const lastSeen = getLastSeenFromSessionStorage();
        removeLastSeenFromSessionStorage();

        return lastSeen;
    }

    if (user) {
        // If the user is logged-in, fetch the last seen timestamp from the server,
        // since we want to make sure to synchronize it across devices.
        console.log('Skjedd Siden Sist: Fetching last seen from server');
        return await getLastSeenFromServer();
    } else {
        // If the user is not logged in, use the last seen timestamp from local storage,
        // since we can't track the user across devices anyway.
        console.log('Skjedd Siden Sist: Fetching last seen from local storage');
        return getLastSeenFromLocalStorage();
    }
};

/**
 * Sets the last seen timestamp.
 *
 * For logged-in users, the timestamp is set on the server.
 * For anonymous users, the timestamp is set in local storage.
 *
 * @returns {Promise<Object>}
 */
export const setLastSeen = async (user) => {
    if (user) {
        // If the user is logged-in, set the last seen timestamp on the server,
        // since we want to make sure to synchronize it across devices.
        console.log('Skjedd Siden Sist: Setting last seen on server');
        return await setLastSeenOnServer();
    } else {
        // If the user is not logged in, set the last seen timestamp in local storage,
        // since we can't track the user across devices anyway.
        console.log('Skjedd Siden Sist: Setting last seen in local storage');
        return setLastSeenInLocalStorage();
    }
};

/**
 * Sets sticky in local storage.
 * @returns {void}
 */
export function setStickySince() {
    localStorage.setItem(
        'skjedd-siden-sist:sticky-since',
        (Date.now() / 1000).toString(),
    );
}

/**
 * Gets sticky from local storage.
 * @returns {number|null} The timestamp when the sticky was set.
 */
export function getStickySince() {
    const sticky = localStorage.getItem('skjedd-siden-sist:sticky-since');
    return sticky ? Math.floor(parseInt(sticky, 10)) : null;
}

/**
 * Determines whether the sticky should be shown.
 * @returns {boolean} True if the sticky should be shown, otherwise false.
 */
export function isSticky() {
    const stickySince = getStickySince();

    if (!stickySince) {
        return false;
    }

    const now = Math.floor(Date.now() / 1000); // Current time in seconds
    const elapsedTime = now - stickySince;

    // Return true if it's been less than 7 days
    return elapsedTime < 3600 * 24 * 7;
}

export const getStories = async ({ topK, timestamp }) => {
    // Session storage will be set for users who logged in for Skjedd siden sist, so we
    // can give them the same stories they logged in for (it sucks to log in for something
    // and then get something else)
    if (getStoriesFromSessionStorage()) {
        console.log(
            'Skjedd Siden Sist: Getting cached stories from session storage',
        );
        let stories = getStoriesFromSessionStorage();
        removeStoriesFromSessionStorage();

        return stories;
    }

    const url = new URL(API_URL + 'update');

    url.searchParams.append('top_k', topK);
    url.searchParams.append('timestamp', timestamp);

    addDebugDays(url);

    const response = await fetchWithTimeout(url.toString());
    const data = await response.json();

    return data.message;
};
